<template>
<v-layout row wrap pt-3 gap-xs-3 class="moods-card">
  <v-flex xs12>
    <v-layout row wrap justify-center align-center>
      <v-flex xs6 md4>
        <v-icon class="primary--text">$vuetify.icons.starling_mood_tracker</v-icon>
      </v-flex>
    </v-layout>
  </v-flex>

  <v-flex xs12>
    <p class="starling-text" v-if="active === 0" v-html="$t('app.tools.mood_tracker.tabs.tool.motivational')"></p>
    <p class="starling-text" v-if="active === 1" v-html="$t('app.tools.mood_tracker.tabs.daily.motivational')"></p>
    <p class="starling-text" v-if="active === 2" v-html="$t('app.tools.mood_tracker.tabs.history.motivational')"></p>
  </v-flex>

  <v-flex xs12>
    <v-tabs v-model="active"
      fixed-tabs
      centered
      grow
      slider-color="primary"
      @change="loadMoods">

      <v-tab key="tracker" ripple class="text-capitalize">
        {{$t('app.tools.mood_tracker.tabs.tool.title')}}
      </v-tab>

      <v-tab key="daily" ripple class="text-capitalize">
        {{$t('app.tools.mood_tracker.tabs.daily.title')}}
      </v-tab>

      <v-tab key="timeline" ripple class="text-capitalize">
        {{$t('app.tools.mood_tracker.tabs.history.title')}}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="active" touchless>
      <v-tab-item key="track">
        <add-mood class="py-3" v-if="active === 0" @created-mood="active=1"></add-mood>
      </v-tab-item>

      <v-tab-item key="daily">
        <mood-daily class="py-3" v-if="active === 1"></mood-daily>
      </v-tab-item>

      <v-tab-item key="timeline">
        <mood-trends class="py-3" v-if="active === 2"></mood-trends>
      </v-tab-item>
    </v-tabs-items>
  </v-flex>
</v-layout>
</template>

<script>
import MoodDaily from './components/moodHistory/mood-daily';
import MoodTrends from './components/moodHistory/mood-trends';
import AddMood from './components/add-mood';

export default {
  name: 'moodsView',
  components: {
    AddMood,
    MoodTrends,
    MoodDaily,
  },
  data() {
    return {
      active: this.$route.query.tab ? parseInt(this.$route.query.tab) : 0,
    };
  },
  watch: {
    active(newValue) {
      this.$log.debug('Tab changed to ', newValue);
      this.$router.replace({ query: { tab: newValue } });
      document.getElementsByClassName('v-toolbar')[0].scrollIntoView();
    },
  },
  mounted() {
    this.loadMoods();
  },
  methods: {
    loadMoods() {
      this.$store.dispatch('moods/getMoods');
    },
  },
};
</script>

<style scoped>
.v-card.moods-card {
  border-radius: 15px;
}
</style>
