var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _vm.currentStep === 0
        ? _c(
            "v-flex",
            { attrs: { xs12: "", md8: "" } },
            [
              _c("add-mood-emotions", {
                key: _vm.currentStep,
                attrs: { form: _vm.form, submit: _vm.advanceStep },
                on: {
                  "update:form": function($event) {
                    _vm.form = $event
                  }
                }
              })
            ],
            1
          )
        : _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("add-mood-intensities", {
                key: _vm.currentStep + 1,
                attrs: {
                  mood: _vm.form,
                  loading: _vm.loading,
                  back: _vm.goBackStep
                },
                on: { "mood-submit": _vm.submitMood }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }