import { render, staticRenderFns } from "./add-mood.vue?vue&type=template&id=1983346b&"
import script from "./add-mood.vue?vue&type=script&lang=js&"
export * from "./add-mood.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1983346b')) {
      api.createRecord('1983346b', component.options)
    } else {
      api.reload('1983346b', component.options)
    }
    module.hot.accept("./add-mood.vue?vue&type=template&id=1983346b&", function () {
      api.rerender('1983346b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tools/moods/components/add-mood.vue"
export default component.exports