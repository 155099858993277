var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "moods-card",
      attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "", md4: "" } },
                [
                  _c("v-icon", { staticClass: "primary--text" }, [
                    _vm._v("$vuetify.icons.starling_mood_tracker")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm.active === 0
          ? _c("p", {
              staticClass: "starling-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.mood_tracker.tabs.tool.motivational")
                )
              }
            })
          : _vm._e(),
        _vm.active === 1
          ? _c("p", {
              staticClass: "starling-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.mood_tracker.tabs.daily.motivational")
                )
              }
            })
          : _vm._e(),
        _vm.active === 2
          ? _c("p", {
              staticClass: "starling-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("app.tools.mood_tracker.tabs.history.motivational")
                )
              }
            })
          : _vm._e()
      ]),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "fixed-tabs": "",
                centered: "",
                grow: "",
                "slider-color": "primary"
              },
              on: { change: _vm.loadMoods },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c(
                "v-tab",
                {
                  key: "tracker",
                  staticClass: "text-capitalize",
                  attrs: { ripple: "" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("app.tools.mood_tracker.tabs.tool.title")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-tab",
                {
                  key: "daily",
                  staticClass: "text-capitalize",
                  attrs: { ripple: "" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.tools.mood_tracker.tabs.daily.title")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "v-tab",
                {
                  key: "timeline",
                  staticClass: "text-capitalize",
                  attrs: { ripple: "" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("app.tools.mood_tracker.tabs.history.title")
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c(
                "v-tab-item",
                { key: "track" },
                [
                  _vm.active === 0
                    ? _c("add-mood", {
                        staticClass: "py-3",
                        on: {
                          "created-mood": function($event) {
                            _vm.active = 1
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: "daily" },
                [
                  _vm.active === 1
                    ? _c("mood-daily", { staticClass: "py-3" })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: "timeline" },
                [
                  _vm.active === 2
                    ? _c("mood-trends", { staticClass: "py-3" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }