<template>
<v-layout row wrap>
  <v-flex xs12 md8 v-if="currentStep === 0">
    <add-mood-emotions
            :key="currentStep"
            :form.sync="form"
            :submit="advanceStep">
    </add-mood-emotions>
  </v-flex>

  <v-flex xs12 v-else>
    <add-mood-intensities
            :key="currentStep + 1"
            :mood="form"
            @mood-submit="submitMood"
            :loading="loading"
            :back="goBackStep">
    </add-mood-intensities>
  </v-flex>
</v-layout>
</template>

<script>
import { MoodTypeEnum } from '@/scripts/store/modules/tools/moods/types';
import addMoodEmotions from '@/views/tools/moods/components/addMood/add-mood-emotions.vue';
import addMoodIntensities from '@/views/tools/moods/components/addMood/add-mood-intensities.vue';

export default {
  name: 'add-mood',
  components: {
    addMoodEmotions,
    addMoodIntensities,
  },
  data() {
    return {
      loading: false,
      currentStep: 0,
      form: {
        reasonText: '',
        date: new Date(),
        moodIntensities: Object.values(MoodTypeEnum).reduce((previous, value) => ({ ...previous, [value]: { selected: false, value: null } }), {}),
      },
    };
  },
  methods: {
    advanceStep() {
      if (this.currentStep < 1 && !this.disableNext) this.currentStep += 1;
      this.$el.scrollIntoView();
    },
    goBackStep() {
      if (this.currentStep === 1) this.currentStep -= 1;
      this.$el.scrollIntoView();
    },
    submitMood(event) {
      this.loading = true;
      this.$store.dispatch('moods/postMood', event)
        .then(res => {
          const successMessage = this.$t('app.tools.mood_tracker.notifications.created');
          if (res && (!res.status || res.status < 300)) {
            this.$emit('created-mood', res);
            this.$store.commit('setNotification', { text: successMessage });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
